import React, { FC, useEffect, useContext } from 'react';
import { Outlet } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { useFetchUser } from '@shared/hooks';

import { authContext } from '@store/index';

import LayoutMenu from './Menu';

const Layout: FC = () => {
  const [userData, fetchUser] = useFetchUser();
  const [authData] = useContext(authContext);

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}
            >
              GameInvitation.com
            </Typography>

            <LayoutMenu authData={authData} />
          </Toolbar>
        </Container>
      </AppBar>
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default Layout;
