import React, { FC, useEffect } from 'react';

import { Container } from '@mui/material';

import { MasterKey } from '@shared/types';

import CreateKeyForm from './CreateKeyForm';
import KeysTable from './KeysTable';
import { useFetchGameKeys, useDeleteGameKey } from './master.actions';

import './index.scss';

interface MasterPageProps {
  masterKey: MasterKey;
}

const MasterPage: FC<MasterPageProps> = ({ masterKey }) => {
  const [gameKeys, fetchGameKeys] = useFetchGameKeys();
  const [_, deleteGameKey] = useDeleteGameKey();

  useEffect(() => {
    fetchGameKeys();
  }, []);

  return (
    <Container className="masterContainer" maxWidth="xl">
      <CreateKeyForm refetch={fetchGameKeys} />
      <div className="gameKeysTableWrapper">
        <KeysTable
          onDeleteClick={deleteGameKey}
          refetch={fetchGameKeys}
          gameKeys={gameKeys}
        />
      </div>
    </Container>
  );
};

export default MasterPage;
