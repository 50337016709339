import React, { FC, useEffect, useState } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useNavigate } from 'react-router-dom';
import { Unity, useUnityContext } from 'react-unity-webgl';

import { ReactUnityEventParameter } from 'react-unity-webgl/distribution/types/react-unity-event-parameters';

import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import {
  Box,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material';

import { useResponsiveUnity } from './hooks';
import { UnityComponentProps } from './types';

import './index.scss';

export const UnityComponent: FC<UnityComponentProps> = ({
  loaderUrl,
  dataUrl,
  frameworkUrl,
  isFullScreenEnabled = false,
  codeUrl,
  resolution,
}) => {
  const [devicePixelRatio, setDevicePixelRatio] = useState(
    window.devicePixelRatio
  );

  const unityContext = useUnityContext({
    loaderUrl,
    dataUrl,
    frameworkUrl,
    codeUrl,
  });

  useResponsiveUnity(resolution);

  const {
    unityProvider,
    loadingProgression,
    isLoaded,
    addEventListener,
    removeEventListener,
  } = unityContext;

  const handle = useFullScreenHandle();

  const navigate = useNavigate();

  const reloadPage = () => {
    navigate('/game');
  };

  useEffect(() => {
    addEventListener('ExitGame', reloadPage as () => ReactUnityEventParameter);

    return () => {
      removeEventListener(
        'ExitGame',
        reloadPage as () => ReactUnityEventParameter
      );
    };
  }, [addEventListener, removeEventListener]);

  useEffect(() => {
    const updateDevicePixelRatio = function () {
      setDevicePixelRatio(window.devicePixelRatio);
    };
    const mediaMatcher = window.matchMedia(
      `screen and (resolution: ${devicePixelRatio}dppx)`
    );

    mediaMatcher.addEventListener('change', updateDevicePixelRatio);

    return () => {
      mediaMatcher.removeEventListener('change', updateDevicePixelRatio);
    };
  }, [devicePixelRatio]);

  const loadingPercentage = Math.round(loadingProgression * 100);

  const { active, exit, enter } = handle;

  const fullScreenTitle = active
    ? 'Нажмите, чтобы выйти из полноэкранного режима'
    : 'Нажмите, чтобы войти в полноэкранный режим';

  return (
    <FullScreen className="fullScreenContainer" handle={handle}>
      <Box id="unity-container" className="unityContainer">
        <Unity
          unityProvider={unityProvider}
          className="unityGame"
          devicePixelRatio={devicePixelRatio}
        />
        {!isLoaded && (
          <Box className="loadingContainer">
            <LinearProgress
              variant="determinate"
              value={loadingPercentage}
              className="linearProgress"
            />
            <Typography className="percentageContainer">{`${loadingPercentage}%`}</Typography>
          </Box>
        )}
        {isFullScreenEnabled && (
          <Tooltip title={fullScreenTitle} placement="left">
            <IconButton
              className="fullScreenIconContainer"
              onClick={active ? void exit : void enter}
            >
              {active ? <FullscreenExitIcon /> : <FullscreenIcon />}
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </FullScreen>
  );
};
