import React, { FC, useState } from 'react';

import HelpIcon from '@mui/icons-material/Help';
import {
  Tooltip as MuiTooltip,
  ClickAwayListener,
  Button,
} from '@mui/material';

interface TooltipProps {
  content: string;
  className?: string;
}

const Tooltip: FC<TooltipProps> = ({ content, className = '' }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <MuiTooltip
        className={className}
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        title={content}
      >
        <Button size="small" onClick={handleTooltipOpen}>
          <HelpIcon />
        </Button>
      </MuiTooltip>
    </ClickAwayListener>
  );
};

export default Tooltip;
