import { Area } from 'react-easy-crop';


export function readFile(file: File) {
  return new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      (e) => {
        const img = new Image();
        img.onload = function () {
          // Dynamically create a canvas element
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d') as any;

          const originalImageWidth = img.width;
          const originalImageHeight = img.height;

          if (originalImageWidth > 5000 || originalImageHeight > 5000) {
            // return console.log('Image should be less than 5.000 px');
            reject('Image should be less than 5.000 px');
          }
          if (originalImageWidth > originalImageHeight) {
            canvas.width = 2048 * (originalImageWidth / originalImageHeight);
            canvas.height = 2048;
          } else {
            canvas.width = 2048;
            canvas.height = 2048 * (originalImageHeight / originalImageWidth);
          }

          // var canvas = document.getElementById("canvas");

          // Actual resizing
          ctx.drawImage(
            img,
            0,
            0,
            img.width,
            img.height,
            0,
            0,
            canvas.width,
            canvas.height
          );

          // Show resized image in preview element
          const dataurl = canvas.toDataURL(file.type);
          resolve(dataurl as string);
        };
        img.src = (e.target as any).result as any;
      },
      false
    );
    reader.readAsDataURL(file);
  });
}

export const createImage = (url: string) =>
  new Promise<HTMLImageElement>((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.src = url.startsWith('data:image') ? url : `${url}?t=${Date.now()}`;
    image.setAttribute('crossOrigin', 'anonymous');
  });

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

export function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation);

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}




export async function getCroppedImg(
  canvasImage: HTMLImageElement,
  pixelCrop: Area,
  fileName: string,
  rotation = 0
) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    canvasImage.width,
    canvasImage.height,
    rotation
  );

  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.translate(-canvasImage.width / 2, -canvasImage.height / 2);
  // draw rotated image
  ctx.drawImage(canvasImage, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height
  );

  // // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As a blob
  return new Promise<File>((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        reject('Failed to crop the image.');
      } else {
        resolve(new File([blob], fileName));
      }
    }, 'image/jpeg');
  });
}

