import { useCallback } from 'react';

import useAxios from '@services/api';

export const useIncreaseGameBuildRunCount = () => {
  const { response, error, issueRequest } = useAxios();

  const increaseGameBuildRunCount = useCallback((buildId: string) => {
    void issueRequest({ url: `/game/build/${buildId}`, method: 'put' });
  }, []);

  return [response, increaseGameBuildRunCount] as const;
};
