import React, { FC } from 'react';

import { CarouselProvider, Slider, Slide, Dot } from 'pure-react-carousel';

import { Stack, Box } from '@mui/material';

import { useIsMobile } from '@shared/hooks';

import styles from './example-image-carousel.module.scss';

import 'pure-react-carousel/dist/react-carousel.es.css';

interface ExampleImagesCarouselProps {
  imageUrls: string[];
}

const ExampleImagesCarousel: FC<ExampleImagesCarouselProps> = ({
  imageUrls,
}) => {
  const isMobile = useIsMobile();

  const renderCarousel = () => {
    return (
      <CarouselProvider
        naturalSlideWidth={290}
        naturalSlideHeight={516}
        totalSlides={3}
      >
        <Slider className={styles.slider}>
          {imageUrls.map((url, index) => (
            <Slide key={url} className={styles.galleryImageSlide} index={index}>
              <div className={styles.imageWrapper}>
                <Box
                  className={styles.stackImage}
                  component="img"
                  alt=""
                  src={url}
                />
              </div>
            </Slide>
          ))}
        </Slider>
        <div className={styles.dotGroup}>
          {imageUrls.map((url, index) => (
            <Dot
              key={url}
              disabled={false}
              slide={index}
              className={styles.dot}
            />
          ))}
        </div>
      </CarouselProvider>
    );
  };

  const renderStackedImages = () => {
    return (
      <Stack direction="row" spacing={2}>
        {imageUrls.map((url) => (
          <div key={url} className={styles.imageWrapper}>
            <Box
              className={styles.stackImage}
              component="img"
              alt=""
              src={url}
            />
          </div>
        ))}
      </Stack>
    );
  };

  return <>{isMobile ? renderCarousel() : renderStackedImages()}</>;
};

export default ExampleImagesCarousel;
