import { Area } from 'react-easy-crop';

import dayjs from 'dayjs';

import type { GameBuild, Game } from '@shared/types';

import { getBaseUrl, API_BASE } from '@services/api';

import {
  readFile,
  getCroppedImg,
  createImage,
} from './ImageCropper/file-utils';

function filterCallback(
  image: File | string | undefined
): image is File | string {
  return !!image;
}

export function rotateImages(images: (File | string | undefined)[]) {
  const uploadedImages = images.filter(filterCallback);

  let rotatingIndex = 0;

  return images.map((image) => {
    if (image) {
      return image;
    }

    rotatingIndex === uploadedImages.length - 1
      ? (rotatingIndex = 0)
      : (rotatingIndex += 1);

    return uploadedImages[rotatingIndex];
  });
}

export const cropImage = async (image: string | File, croppedPixels: Area) => {
  let imageUrl;
  let fileName;

  if (typeof image === 'string') {
    imageUrl = image;
    fileName = image.split('/').pop() || image;
  } else {
    imageUrl = await readFile(image);
    fileName = image.name;
  }

  const canvasImage = await createImage(imageUrl);

  return getCroppedImg(canvasImage, croppedPixels, fileName);
};

export const getMusicUrl = (
  gameBuild: GameBuild,
  existingGameBuild: GameBuild,
  gameDefaults: Game
) => {
  if (gameBuild) {
    return gameBuild.musicUrl;
  }

  if (existingGameBuild) {
    return existingGameBuild.musicUrl;
  }

  if (gameDefaults) {
    return gameDefaults.defaultMusicUrl;
  }

  return '';
};

export const generateGameLink = (buildId: string, isTesting: boolean) => {
  return `${getBaseUrl()}/game?buildId=${buildId}${
    isTesting ? '&isTesting=true' : ''
  }`;
};

export const getEndEditDate = (
  activateDate: Date | null,
  editDayCount: number
) => dayjs(activateDate).add(editDayCount, 'day').format('DD/MM/YYYY HH:mm:ss');

export const getIsClientFormDisabled = (
  activateDate: Date | null,
  editDayCount: number
) => {
  if (activateDate === null) {
    return false;
  }

  return dayjs(activateDate).add(editDayCount, 'day').isBefore(dayjs());
};
