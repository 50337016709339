import React, { FC, useEffect, useState } from 'react';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {
  InputLabel,
  OutlinedInput,
  InputAdornment,
  FormControl,
  IconButton,
  Snackbar,
} from '@mui/material';

interface ClipboardBtnProps {
  content: string;
}

const ClipboardBtn: FC<ClipboardBtnProps> = ({ content }) => {
  const [open, setOpen] = useState(false);

  const handleCopyClick = () => {
    setOpen(true);
    navigator.clipboard.writeText(content);
  };

  return (
    <FormControl variant="outlined">
      <InputLabel htmlFor="clipboard-button">
        Ссылка на готовую игру
      </InputLabel>
      <OutlinedInput
        id="clipboard-button"
        fullWidth
        type="text"
        defaultValue={content}
        disabled
        size="small"
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleCopyClick}
              edge="end"
            >
              <ContentCopyIcon />
            </IconButton>
          </InputAdornment>
        }
        label="Link to the finished game"
      />
      <Snackbar
        message="Copied to clibboard"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </FormControl>
  );
};

export default ClipboardBtn;
