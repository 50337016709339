export const px2vw = (size: number, width = 1440) =>
  `${(size / width) * 100}vw`;

export const exportFile = async (blob: Blob, fileName: string) => {
  const objectUrl = window.URL.createObjectURL(blob);
  const link = document.createElement('a');

  link.href = objectUrl;
  link.setAttribute('download', fileName);

  document.body.appendChild(link);

  link.click();

  document.body.removeChild(link);

  window.URL.revokeObjectURL(objectUrl);
};
