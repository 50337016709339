// @ts-nocheck

import { useEffect, useCallback } from 'react';

import { pick, omit } from 'lodash';

import type { Game } from '@shared/types';

import useAxios from '@services/api';

import { GameBuild } from './client.types';
import type { SubmittedForm, CropPixelsData } from './client.types';
import { cropImage, rotateImages } from './client.utils';

export const useFetchGameDefaults = (name: string) => {
  const { response, error, issueRequest } = useAxios<Game>();

  useEffect(() => {
    void issueRequest({ url: `/games/${name}`, method: 'get' });
  }, [name]);

  return response;
};

export const useFetchGameDefaultsLazy = () => {
  const { response, error, issueRequest, loading } = useAxios<Game>();

  const useFetchGameDefaultsLazy = useCallback((name: string) => {
    void issueRequest({ url: `/games/${name}`, method: 'get' });
  }, []);

  return [response, useFetchGameDefaultsLazy, loading] as const;
};

export const useFetchGameBuild = (id: string) => {
  const { response, error, issueRequest, loading } = useAxios<GameBuild>();

  useEffect(() => {
    void issueRequest({ url: `/games/build/${id}`, method: 'get' });
  }, [id]);

  return [response, loading] as const;
};

export const useCreateGameBuild = () => {
  const { response, error, issueRequest, loading, setLoading } =
    useAxios<GameBuild>();

  const handleSubmit = useCallback(
    (
      data: SubmittedForm,
      croppedPixelsData: CropPixelsData,
      tiles: string[]
    ) => {
      setLoading(true);

      const pendingPromises = [];

      for (const [fieldName, pixels] of Object.entries(croppedPixelsData)) {
        if (!pixels) {
          continue;
        }

        if (!isNaN(parseInt(fieldName, 10))) {
          pendingPromises.push(
            cropImage(data.gameTiles[fieldName], pixels).then(
              (croppedImage) => {
                data.gameTiles[fieldName] = croppedImage;
              }
            )
          );
        } else {
          pendingPromises.push(
            cropImage(data[fieldName], pixels).then((croppedImage) => {
              data[fieldName] = croppedImage;
            })
          );
        }
      }

      Promise.all(pendingPromises)
        .then(() => {
          data.gameTiles = rotateImages(tiles);

          const formData = new FormData();

          const fileFields = [
            'startGameImage',
            'endGameImage',
            'gameTiles',
            'customMusicFile',
          ];
          const fileUrls = <(string | null)[]>[];

          if (!data.enableCustomMusic) {
            delete data.customMusicFile;
          }

          // Why lodash doesn't infer types
          const files: Pick<
            SubmittedForm,
            'startGameImage' | 'endGameImage' | 'gameTiles' | 'customMusicFile'
          > = pick(data, fileFields);
          const plainFields: Omit<
            SubmittedForm,
            'startGameImage' | 'endGameImage' | 'customMusicFile' | 'gameTiles'
          > & { gameTiles: (string | null)[] } = omit(data, fileFields);

          // How to specify types for object.entries
          for (const [fieldName, fileObject] of Object.entries(files)) {
            // Why type guard didn't work
            if (fieldName === 'gameTiles') {
              for (const imageFile of files[fieldName]) {
                if (typeof imageFile === 'string') {
                  fileUrls.push(imageFile);
                } else {
                  fileUrls.push(null);
                  formData.append(fieldName, imageFile);
                }
              }
            } else {
              formData.append(fieldName, fileObject);
            }
          }

          plainFields.gameTiles = fileUrls;

          const jsonData = JSON.stringify(plainFields);

          formData.append('jsonData', jsonData);

          void issueRequest({
            url: `/games/build`,
            method: 'put',
            body: formData,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    []
  );

  return [response, handleSubmit, loading] as const;
};

export const useSetActivateGameDate = () => {
  const { response, error, issueRequest } = useAxios<Record<string, never>>();

  const useSetActivateGameDate = useCallback((id: string) => {
    void issueRequest({ url: `/games/keys/${id}`, method: 'put' });
  }, []);

  return [response, useSetActivateGameDate] as const;
};
