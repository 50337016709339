import { useEffect, useState, useCallback } from 'react';

import { useFetchUser } from '@shared/hooks';

import useAxios from '@services/api';

import { FormInputs } from './login.types';

export const useLogin = () => {
  const [userData, fetchUser] = useFetchUser();

  useEffect(() => {
    fetchUser();
  }, []);

  const { response, error, issueRequest, loading, statusCode } =
    useAxios<Record<string, never>>();

  const [loginError, setLoginError] = useState<{ [message: string]: string }>({
    message: '',
  });

  const handleSubmit = useCallback((data: FormInputs) => {
    void issueRequest({ url: `/login`, method: 'post', body: data });
  }, []);

  useEffect(() => {
    if (!statusCode || loading) {
      return;
    }

    console.log('Effect', statusCode);

    if (statusCode === 200 || statusCode === 401) {
      fetchUser();
    }

    if (statusCode === 401) {
      setLoginError({ message: 'Invalid key' });
    }

    if (statusCode === 403) {
      setLoginError({ message: 'Key has expired' });
    }
  }, [statusCode, response, loading]);

  return [response, handleSubmit, loading, loginError] as const;
};
