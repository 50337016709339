import React, { FC, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';

import { isEmpty } from 'lodash';

import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Box,
  Checkbox,
  Alert,
  AlertTitle,
} from '@mui/material';

import { useFetchGames, useCreateKey } from './create-key-form.actions';
import type {
  CreateKeyFormInputs,
  CreateKeyFormProps,
} from './create-key-form.types';

import styles from './create-key-form.module.scss';

const {
  formContainer,
  errorContainer,
  textareaContainer,
  whiteLabelCheckboxContainer,
} = styles;

const CreateKeyForm: FC<CreateKeyFormProps> = ({ refetch }) => {
  const [gameKey, handleCreateKeySubmit, loading] = useCreateKey();
  const [games] = useFetchGames();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<CreateKeyFormInputs>();

  useEffect(() => {
    if (gameKey) {
      refetch();
    }
  }, [gameKey]);

  if (!games) {
    return null;
  }

  const onSubmit = (data: CreateKeyFormInputs) => {
    handleCreateKeySubmit(data);
  };

  return (
    <>
      <Typography variant="h5" noWrap>
        Create Key
      </Typography>
      <form onSubmit={(...args) => void handleSubmit(onSubmit)(...args)}>
        <Box className={formContainer}>
          <FormControl sx={{ width: '100%' }}>
            <InputLabel id="gameName">Select Game</InputLabel>
            <Controller
              name="gameName"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <Select
                  {...field}
                  size="small"
                  error={!!errors?.gameName}
                  label="Select Game"
                  variant="filled"
                  labelId="gameName"
                >
                  {games.map((game) => (
                    <MenuItem key={game.title} value={game.title}>
                      {game.title}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
          <FormControl sx={{ width: '100%' }}>
            <Controller
              name="editDayCount"
              defaultValue={1}
              control={control}
              rules={{ required: true, validate: (value) => value > 0 }}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  type="number"
                  error={!!errors?.editDayCount}
                  label="Enter edit day count"
                />
              )}
            />
          </FormControl>
          <FormControl sx={{ width: '100%' }}>
            <Controller
              name="gameDayCount"
              defaultValue={1}
              control={control}
              rules={{ required: true, validate: (value) => value > 0 }}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  type="number"
                  error={!!errors?.gameDayCount}
                  label="Enter game day count"
                />
              )}
            />
          </FormControl>
          <FormControl sx={{ width: '100%' }}>
            <Controller
              name="gamesCount"
              defaultValue={1}
              control={control}
              rules={{ required: true, validate: (value) => value > 0 }}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  type="number"
                  error={!!errors?.gamesCount}
                  label="Enter games count"
                />
              )}
            />
          </FormControl>
          <FormControl sx={{ width: '100%' }}>
            <Controller
              name="notion"
              defaultValue=""
              control={control}
              rules={{ required: false }}
              render={({ field }) => (
                <Box className={textareaContainer}>
                  <TextField
                    {...field}
                    size="small"
                    multiline
                    rows={5}
                    inputProps={{
                      maxLength: 200,
                    }}
                    error={!!errors?.notion}
                    label="Enter notion"
                  />
                  <Typography marginLeft="10px" fontSize="14px">
                    {field.value.length}/200
                  </Typography>
                </Box>
              )}
            />
          </FormControl>
          <FormControl>
            <Controller
              name="isWhiteLabel"
              defaultValue={false}
              control={control}
              render={({ field }) => (
                <Box className={whiteLabelCheckboxContainer}>
                  <Checkbox {...field} size="small" />
                  <Typography fontSize="14px">White Label</Typography>
                </Box>
              )}
            />
          </FormControl>
        </Box>
        {!isEmpty(errors) && (
          <Box className={errorContainer}>
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              Make sure all required fields are filled.
            </Alert>
          </Box>
        )}
        <Grid item xs={12} md={4}>
          <Button
            disabled={loading}
            size="small"
            type="submit"
            variant="contained"
          >
            Create Key
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default CreateKeyForm;
