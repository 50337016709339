import { useEffect, useCallback } from 'react';

import type { GameKey } from '@shared/types';

import useAxios from '@services/api';

import type {
  MasterAvailableGames,
  CreateKeyFormInputs,
} from './create-key-form.types';

export const useFetchGames = () => {
  const { response, error, issueRequest } = useAxios<MasterAvailableGames[]>();

  useEffect(() => {
    void issueRequest({ url: `/admin/games`, method: 'get' });
  }, []);

  return [response];
};

export const useCreateKey = () => {
  const { response, error, issueRequest, loading } = useAxios<GameKey>();

  const handleSubmit = useCallback((data: CreateKeyFormInputs) => {
    const { gamesCount, editDayCount, gameDayCount } = data;

    void issueRequest({
      url: `/admin/games/keys`,
      method: 'post',
      body: {
        ...data,
        gamesCount: Number(gamesCount),
        editDayCount: Number(editDayCount),
        gameDayCount: Number(gameDayCount),
      },
    });
  }, []);

  return [response, handleSubmit, loading] as const;
};
