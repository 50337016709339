import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Box } from '@mui/material';

import { UnityComponent } from '@shared/components';

import {
  useFetchGameBuild,
  useFetchGameDefaultsLazy,
} from '../Client/client.actions';

import { useIncreaseGameBuildRunCount } from './game.actions';

import './index.scss';

const GamePage = () => {
  const [searchParams] = useSearchParams();
  const [_, increaseGameBuildRunCount] = useIncreaseGameBuildRunCount();

  const currentBuildId = searchParams.get('buildId');

  useEffect(() => {
    if (currentBuildId !== null) {
      increaseGameBuildRunCount(currentBuildId);
    }
  }, []);

  const [gameBuild] = useFetchGameBuild(currentBuildId ?? '');

  const { gameName = '' } = gameBuild ?? {};

  const [gameDefaults, fetchGameDefaultsLazy, loading] =
    useFetchGameDefaultsLazy();

  useEffect(() => {
    if (gameName !== '') {
      fetchGameDefaultsLazy(gameName);
    }
  }, [gameName]);

  const unityResolution = 1920 / 1080;

  return (
    <Box
      className="mainPage"
      style={{ backgroundColor: gameDefaults?.backgroundColor ?? 'white' }}
    >
      <UnityComponent
        //loaderUrl="../webgl-build/loader.js"
        // dataUrl="../webgl-build/game.data"
        // frameworkUrl="../webgl-build/framework.js"
        // codeUrl="../webgl-build/code.wasm"
        // resolution={unityResolution}

        // BR loader
        // loaderUrl="../webgl-build/br/loader.js"
        // dataUrl="../webgl-build/br/data.br"
        // frameworkUrl="../webgl-build/br/framework.js.br"
        // codeUrl="../webgl-build/br/wasm.br"
        // resolution={unityResolution}

        //S3 loader
        // loaderUrl="https://eatsy-webgl.s3.eu-central-1.amazonaws.com/mainBuild/loader.js"
        // dataUrl="https://eatsy-webgl.s3.eu-central-1.amazonaws.com/mainBuild/game.data"
        // frameworkUrl="https://eatsy-webgl.s3.eu-central-1.amazonaws.com/mainBuild/framework.js"
        // codeUrl="https://eatsy-webgl.s3.eu-central-1.amazonaws.com/mainBuild/code.wasm"

        loaderUrl={import.meta.env.VITE_LOADER ?? ""}
        dataUrl={import.meta.env.VITE_DATA ?? ""}
        frameworkUrl={import.meta.env.VITE_FRAMEWORK ?? ""}
        codeUrl={import.meta.env.VITE_CODE ?? ""}

        resolution={unityResolution}
      />
    </Box>
  );
};

export default GamePage;
