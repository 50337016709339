import React, { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

const ConditionalRoute: FC<ConditionalRouteProps> = ({
  condition,
  redirectTo,
  children,
}) => condition ? <>{children}</> : <Navigate to={redirectTo} replace />;

export type ConditionalRouteProps = {
  condition: boolean
  redirectTo: string
  children?: ReactNode,
};

export default ConditionalRoute;
