import React, {
  ChangeEvent,
  useState,
  useCallback,
  memo,
  useEffect,
} from 'react';
import Cropper, { Area } from 'react-easy-crop';

import { Box, Button, Typography, Stack } from '@mui/material';

import Tooltip from '@shared/components/Tooltip';
import type { FileEventTarget } from '@shared/types';

import { getCroppedImg, createImage, readFile } from '../file-utils';

import type { DesktopCropperProps } from './desktop-cropper.types';

import styles from '../image-cropper.module.scss';

const DesktopCropper = ({
  label,
  onChange,
  value,
  maskImage,
  aspectRatio,
  toolTipContent,
  headerDirection,
  containerClassName = '',
}: DesktopCropperProps) => {
  const [file, setFile] = useState<File>();
  const [imageSrc, setImageSrc] = useState<string>('');
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [errorImage, setErrorImage] = useState(false);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as FileEventTarget;

    if (target.files && target.files.length > 0) {
      const file = target.files[0];

      setFile(file);
      readFile(file)
        .then(setImageSrc)
        .catch((e) => {
          console.log(e);
          setErrorImage(true);
          console.log(errorImage);
        });
    }
  };

  useEffect(() => {
    if (!value) {
      setImageSrc('');
      return;
    }

    if (typeof value === 'string') {
      setCrop({ x: 0, y: 0 });
      setZoom(1);
      setImageSrc(value);
      return;
    }
  }, [value]);

  const getFileName = () => {
    console.log(file);
    if (file) {
      return file.name;
    }

    console.log(imageSrc);
    if (imageSrc) {
      return imageSrc.split('/').pop() || imageSrc;
    }

    return 'Upload Image';
  };

  const handleCropComplete = useCallback(
    (croppedArea: Area, croppedPixels: Area) => {
      if (croppedPixels.x === crop.x && croppedPixels.y === crop.y) {
        return;
      }

      createImage(imageSrc)
        .then((canvasImage) =>
          getCroppedImg(canvasImage, croppedPixels, getFileName())
        )
        .then(onChange)
        .catch(console.error);
    },
    [imageSrc]
  );

  const handleClearClink = () => {
    setImageSrc('');
    setFile(undefined);

    onChange(null);
  };

  return (
    <div className={`${styles.imageCropperWrapper} ${containerClassName}`}>
      <Stack
        className={styles.imageCropperHeader}
        spacing={1}
        direction={headerDirection}
      >
        <Stack
          className={`${styles.imageCropperHeader} tooltipWrapper`}
          direction="row"
        >
          <Typography variant="h6" noWrap className="cropperLabel">
            {label}
          </Typography>
          {!!toolTipContent && (
            <Tooltip className="tooltip" content={toolTipContent} />
          )}
        </Stack>

        <div>
          <Button
            size="small"
            variant="contained"
            component="label"
            className="uploadBtn"
          >
            <span className="uploadBtnText">{getFileName()}</span>
            <input
              accept="image/*"
              type="file"
              hidden
              onChange={handleFileChange}
            />
          </Button>
          {imageSrc && (
            <Button size="small" color="error" onClick={handleClearClink}>
              Clear
            </Button>
          )}
        </div>
      </Stack>
      {imageSrc && (
        <Box className={styles.cropArea}>
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            zoomSpeed={0.25}
            objectFit="vertical-cover"
            style={{
              cropAreaStyle: {
                ...(maskImage
                  ? {
                      backgroundImage: `url(${maskImage})`,
                      backgroundSize: 'contain',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                    }
                  : {}),
              },
            }}
            aspect={aspectRatio}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={handleCropComplete}
          />
        </Box>
      )}
    </div>
  );
};

export default memo(DesktopCropper);
