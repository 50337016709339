import { useEffect, useCallback } from 'react';

import { useFetchUser } from '@shared/hooks';

import useAxios from '@services/api';

export const useLogout = () => {
  const [userData, fetchUser] = useFetchUser();
  const { response, error, issueRequest, loading, statusCode } =
    useAxios<Record<string, never>>();

  const handleSubmit = useCallback(() => {
    void issueRequest({ url: `/logout`, method: 'post' });
  }, [response, statusCode]);

  useEffect(() => {
    if (statusCode === 200) {
      fetchUser();

      return;
    }
  }, [statusCode, response]);

  return [response, handleSubmit, loading] as const;
};
