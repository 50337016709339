import React, { FC, memo } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import type { ConfirmationDialogProps } from './confirmation-dialog.types';

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  onConfirm,
  onClose,
  isOpen,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Do you want to delete the key?
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={onClose}>
          Cancel
        </Button>
        <Button color="error" onClick={onConfirm} size="small" autoFocus>
          Delete Key
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ConfirmationDialog);
