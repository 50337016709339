import React, { FC } from 'react';

import { Button, Menu, MenuItem } from '@mui/material';

import type { Auth } from '@shared/types';

import { useLogout } from './menu.actions';

interface LayoutMenuProps {
  authData: Auth | undefined;
}

const LayoutMenu: FC<LayoutMenuProps> = ({ authData }) => {
  const [response, handleLogoutSubmit] = useLogout();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutClick = () => {
    handleLogoutSubmit();

    handleClose();
  };

  const getMenuTitle = () => {
    if (!authData) {
      return '';
    }

    if (authData.masterKey) {
      return 'Menu';
    }

    if (authData.gameKey) {
      return authData.gameKey.key;
    }
  };

  return (
    <div>
      <Button
        sx={{
          color: 'white',
        }}
        id="basic-button"
        size="small"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {getMenuTitle()}
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
      </Menu>
    </div>
  );
};

export default LayoutMenu;
