import { useEffect } from 'react';

export const useResponsiveUnity = (resolution: number) => {
  const onResize = () => {
    const container = document.getElementById('unity-container');
    const canvas = container
      ? (container.firstElementChild as HTMLCanvasElement)
      : null;

    if (container !== null && canvas !== null) {
      let width;
      let height;

      width = window.innerWidth;
      height = window.innerHeight;

      if (width * resolution > window.innerHeight) {
        width = Math.min(width, Math.ceil(height / resolution));
      }
      height = Math.floor(width * resolution);

      container.style.width = canvas.style.width = width + 'px';
      container.style.height = canvas.style.height = height + 'px';
      container.style.top =
        Math.floor((window.innerHeight - height) / 2) + 'px';
      container.style.left =
        Math.floor((window.innerWidth - height) / 2) + 'px';
    }
  };

  useEffect(() => {
    onResize();

    window.addEventListener('resize', onResize);

    const deviceRegexp = /iPhone|iPad|iPod|Android/i;

    if (deviceRegexp.test(navigator.userAgent)) {
      const meta = document.createElement('meta');

      meta.name = 'viewport';
      meta.content =
        'width=device-width, height=device-height, initial-scale=1.0, user-scalable=no, shrink-to-fit=yes';

      document.getElementsByTagName('head')[0].appendChild(meta);
    }

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);
};
