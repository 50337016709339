import { useEffect, useContext, useCallback } from 'react';

import useMediaQuery from '@mui/material/useMediaQuery';

import type { Auth } from '@shared/types';

import useAxios from '@services/api';

import { authContext } from '@store/index';

export const useFetchUser = () => {
  const [authData, setAuthData] = useContext(authContext);
  const { response, error, issueRequest, loading, statusCode } =
    useAxios<Auth>();

  const fetchUser = useCallback(() => {
    void issueRequest({ url: '/user', method: 'get' });
  }, []);

  useEffect(() => {
    if (response) {
      setAuthData(response);
    }

    if (statusCode === 401) {
      setAuthData(undefined);
    }
  }, [response, statusCode]);

  return [response, fetchUser, loading] as const;
};

export const useIsMobile = () => {
  const matches = useMediaQuery('(max-width:600px)');

  return matches;
};
