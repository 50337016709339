import React, { FC } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { Button, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

import { BaseModalProps } from './base-modal.types';

import styles from './base-modal.module.scss';

const { baseModalContainer, baseModalHeader, baseModalFooter } = styles;

export const BaseModal: FC<BaseModalProps> = ({
  buttonConfirmedText,
  children,
  handleClose,
  headerTitle,
  isLoading,
  isFooterNeeded = true,
  isCloseButtonDisabled = false,
  isSubmitButtonDisabled = false,
  isOpened,
  onSubmit,
}) => (
  <Modal
    open={isOpened}
    onClose={handleClose}
    disableAutoFocus
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
  >
    <Box className={baseModalContainer}>
      <Box className={baseModalHeader}>
        <Typography variant="h6" overflow="hidden" textOverflow="ellipsis">
          {headerTitle}
        </Typography>
        <IconButton onClick={handleClose} disabled={isCloseButtonDisabled}>
          <CloseIcon />
        </IconButton>
      </Box>
      {children}
      {isFooterNeeded && (
        <Box className={baseModalFooter}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            disabled={isCloseButtonDisabled}
            onClick={handleClose}
          >
            Close
          </Button>
          {!isLoading ? (
            <Button
              variant="contained"
              type="submit"
              size="small"
              disabled={isSubmitButtonDisabled}
              onClick={onSubmit}
            >
              {buttonConfirmedText ?? 'Save'}
            </Button>
          ) : (
            <CircularProgress />
          )}
        </Box>
      )}
    </Box>
  </Modal>
);
