import { useState } from 'react';
import { toast } from 'react-toastify';

import config from '@config/index';
import axios, { isAxiosError, AxiosError } from 'axios';

import type { IssueRequest } from '@shared/types';


export const API_BASE = '/api';

axios.defaults.baseURL = getBaseUrl();

interface ApiError {
  message: string;
}

type HandleErrorFn = (message: string, statusCode: number | undefined) => void;

const defaultErrorHandler = (
  message: string,
  statusCode: number | undefined
) => {
  if ((statusCode && statusCode >= 500) || !statusCode) {
    toast.error(message);
  }
};

const useAxios = <Response>(
  handleError: HandleErrorFn = defaultErrorHandler
) => {
  const [response, setResponse] = useState<Response>();
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [statusCode, setStatusCode] = useState<number>();

  const issueRequest: IssueRequest = ({
    url,
    method,
    body,
    query,
    responseType,
  }) => {
    setLoading(true);

    return axios<Response>({
      method,
      url: `${API_BASE}${url}`,
      data: body,
      params: query,
      responseType,
      withCredentials: true,
    })
      .then((res) => {
        setStatusCode(res.status);
        setResponse(res.data);
      })
      .catch((err: Error | AxiosError) => {
        let message;
        let status;

        if (isAxiosError<ApiError>(err)) {
          if (err.response) {
            message = err.response.data.message;
            status = err.response.status;
            setStatusCode(status);
          } else {
            message = 'Failed to initiate a request. Try again.';
          }
        } else {
          message = err.message;
        }

        handleError(message, status);
        setError(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return { response, error, issueRequest, loading, statusCode, setLoading };
};

export function getBaseUrl() {
  const { hostname, protocol } = window.location;
  const isLocalhost = hostname === 'localhost';

  const baseUrl = `${protocol}//${hostname}${
    isLocalhost ? `:${config.serverPort}` : ''
  }`.trim();

  return baseUrl;
}

export default useAxios;
