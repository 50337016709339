import React, { FC } from 'react';
import { ToastContainer } from 'react-toastify';

import { Box } from '@mui/material';

import Router from './router';
import AuthProvider from './store';

import './App.scss';

import 'react-toastify/dist/ReactToastify.css';

export const App: FC = () => {
  return (
    <Box
      style={{
        display: 'flex',
        minHeight: '100vh',
        width: '100%',
        flexDirection: 'column',
        backgroundColor: 'white',
      }}
    >
      <ToastContainer />
      <AuthProvider>
        <Router />
      </AuthProvider>
    </Box>
  );
};
