import React, {
  FC,
  ChangeEvent,
  useRef,
  useState,
  useEffect,
  memo,
} from 'react';

import { Button, Stack } from '@mui/material';

import type { FileEventTarget } from '@shared/types';

import styles from './music-uploader.module.scss';

interface MusicUploaderProps {
  value: File | string;
  isDisabled?: boolean;
  onChange: (e: File) => void;
  setError: (tr: any) => void;
}

const MusicUploader: FC<MusicUploaderProps> = ({
  value,
  onChange,
  setError,
  isDisabled = false,
}) => {
  const audioPlayerRef = useRef<HTMLAudioElement>(null);
  const [audioUrl, setAudioUrl] = useState<string>('');

  useEffect(() => {
    if (!value) {
      return;
    }

    if (typeof value === 'string') {
      return setAudioUrl(value);
    }

    return setAudioUrl(URL.createObjectURL(value));
  }, [value]);

  useEffect(() => {
    if (audioUrl && audioPlayerRef.current) {
      audioPlayerRef.current.pause();
      audioPlayerRef.current.load();
    }
  }, [audioUrl]);

  const handleMusicInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as FileEventTarget;

    if (target.files && target.files.length > 0 && target.files[0].size < 5e6) {
      const file = target.files[0];
      onChange(file);
    } else {
      alert('Файл музыки должен быть меньше 5 мегабайт.');
    }
  };

  const getMusicFileName = (musicFile: File | string | undefined) => {
    if (!musicFile) {
      return 'Upload File';
    }

    if (typeof musicFile === 'string') {
      return musicFile.split('/').pop() || musicFile;
    }

    return musicFile.name;
  };

  return (
    <Stack
      className={styles.musicUploaderWrapper}
      sx={{ alignItems: 'center' }}
      spacing={2}
    >
      <Button
        variant="contained"
        disabled={isDisabled}
        size="small"
        component="label"
        className="uploadBtn"
      >
        <span className="uploadBtnText">{getMusicFileName(value)}</span>
        <input
          accept=".mp3, .ogg"
          type="file"
          hidden
          onChange={handleMusicInputChange}
        />
      </Button>
      {audioUrl && (
        <audio ref={audioPlayerRef} controls>
          <source src={audioUrl} />
        </audio>
      )}
    </Stack>
  );
};

export default memo(MusicUploader);
