import React, {
  FC,
  ReactNode,
  createContext,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';

import type { Auth } from '@shared/types';

type AuthData = Auth | undefined;

type AuthContext = [AuthData, Dispatch<SetStateAction<AuthData>>];

export const authContext = createContext<AuthContext>(
  [] as unknown as AuthContext
);

const UserDetailsProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [authData, setAuthData] = useState<AuthData>();

  return (
    <authContext.Provider value={[authData, setAuthData]}>
      {children}
    </authContext.Provider>
  );
};

export default UserDetailsProvider;
